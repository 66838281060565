.b-school{
  padding: 90px 0 150px 0;
  @include box-sizing;
  background: url("../img/main.webp") center top no-repeat;
  background-size: cover;
  @include _1199{
    padding: 55px 0;
  }
  .b-title{
	margin: 0 0 86px 0;
    @include _991{
      margin: 0 0 45px 0;
    }
    @include _767{
      margin: 0 0 28px 0;
    }
  }
  .row{
    margin-top: -95px;
    margin-left: -30px;
    margin-right: -30px;
    @include _1680{
      margin-left: -15px;
      margin-right: -15px;
      margin-top: -70px;
    }
    @include _767{
      margin-top: -20px;
    }
  }
  .col{
    margin-top: 95px;
    padding-left: 30px;
    padding-right: 30px;
    @include _1680{
      padding-left: 15px;
      padding-right: 15px;
      margin-top: 70px;
    }
    @include _991{
      width: 50%;
    }
    @include _767{
      width: 290px;
      margin: 25px auto 0;
      flex: auto;
    }
  }
  &__item{
    text-align: center;
    &-img{
      margin-bottom: 30px;
      @include _1199{
        margin-bottom: 15px;
      }
      img{
        margin: 0 auto 0;
        @include _1199{
          max-width: 90px;
        }
      }
    }
    &-text{
      p{
        font-weight: 400;
        font-size: 24px;
        line-height: 30px;
        color: #000000;
        @include _1680{
          font-size: 20px;
        }
        @include _1199{
          font-size: 16px;
          line-height: 1.4;
        }
        b{
          display: block;
          font-weight: 700;
        }
      }
    }
  }
}