$main-color: #000000;
h1, h2, h3, h4, h5, h6{
  margin: 0 0 35px 0;
  font-weight: 700;
  color: #28722F;
  line-height: 1.3;
}
h1{
  font-size: 66px;
}
h2{
  font-size: 60px;
}
h3{
  font-size: 56px;
}
h4{
  font-size: 52px;
}
h5{
  font-size: 48px;
}
h6{
  font-size: 44px;
}
p {
  font-weight: 800;
  font-size: 16px;
  line-height: 1.45;
  margin: 0 0 15px 0;
  &:last-child{
  margin-bottom: 0;
  }
  a{
  text-decoration: underline;
  color: #004394;
    &:hover{
      text-decoration: none;
    }
  }
}
strong{
  font-weight: 500;
}
img{
  display: block;
  max-width: 100%;
}
a{
  color: inherit;
  outline: none;
  text-decoration: none;
}
ul , ol{
  padding: 0;
  margin: 0;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  li{
  list-style: none;
  }
}
ol{
  padding: 0 0 0 20px;
}
ol{
  li{
  list-style: decimal;
  }
}
/* buttons */
button,
input,
optgroup,
select,
textarea {
  font-family: inherit; /* 1 */
  margin: 0; /* 2 */
}
input,
select,
textarea {
  font-family: inherit; /* 1 */
  margin: 0; /* 2 */
  border: 1px solid #ffffff;
  &:focus{
  border-color: #3DAF86 !important;
  }
}
button{
  margin:0;
  padding:0;
  border:none;
  background:none;
  cursor:pointer;
  overflow:visible;
  width:auto;
  outline: none;
  box-shadow: none;
}
button {
  &:hover, &:focus, &:active {
  outline: none;
  box-shadow: none;
  text-decoration: none;
  }
}
.b-btn{
  width: 300px;
  height: 80px;
  background: linear-gradient(270deg, #509266 0%, #256F2C 100%);
  border-radius: 15px;
  font-weight: 500;
  font-size: 30px;
  line-height: 1;
  text-transform: uppercase;
  color: #FFFFFF;
  display: flex;
  justify-content: center;
  align-items: center;
  @include transition;
  @include _1680{
    width: 150px;
    height: 40px;
    font-size: 15px;
  }
  @include _767{
    width: 300px;
    height: 50px;
    font-size: 24px;
  }
  &:hover , &:focus , &:active{
    text-decoration: none;
    color: #FFFFFF;
  }
  &:hover{
    filter: contrast(150%);
  }
}
/* ripple-effect */
.ripple {
  position: relative;
  overflow: hidden;
}
.ripple-effect {
  display: inline-block;
  position: absolute;
  top: 0;
  left: 0;
  border: 2px solid rgba(255, 255, 255, .3);
  -webkit-border-radius: 50%;
  border-radius: 50%;
  -webkit-animation: ripple 1s ease forwards;
  animation: ripple 1s ease forwards;
  z-index: 3;
}
@-webkit-keyframes ripple {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  100% {
    -webkit-transform: scale(200);
    transform: scale(200);
  }
}
@keyframes ripple {

  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  100% {
    -webkit-transform: scale(200);
    transform: scale(200);
  }
}

/* inputs */
input{
  outline: none;
  border: none;
}
.b-input{
  width: 100%;
}
.b-input__text{
  width: 100%;
  height: 44px;
  background: #FFFFFF;
  border: 1px solid #FFFFFF;
  border-radius: 3px;
  padding: 0 20px;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #000000;
  @include box-sizing;
  &::-webkit-input-placeholder{
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: #9AA6B8;
  }
  &:-moz-placeholder{
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: #9AA6B8;
  }
}
textarea{
  width: 100%;
  height: 44px;
  background: #FFFFFF;
  border: 2px solid #FFFFFF;
  border-radius: 3px;
  padding: 20px;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #000000;
  @include box-sizing;
  border: 1px solid #ffffff;
  outline: none;
  resize: none;
  &::-webkit-input-placeholder{
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: #9AA6B8;
  }
  &:-moz-placeholder{
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: #9AA6B8;
  }
  &.b-textarea{
    width: 100%;
    min-height: 240px;
    height: auto;
    max-height: 240px;
    &.md{
      min-height: 140px;
      max-height: 140px;
    }
  }
}

/* type file */
.input__wrapper {
  .input__file {
    opacity: 0;
    visibility: hidden;
    position: absolute;
  }
  .input__file-icon-wrapper {
    width: 44px;
    height: 44px;
    background: #FFFFFF;
    border-radius: 3px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
  }
  .input__file-button-text {
    line-height: 1;
    margin-top: 1px;
  }
  .input__file-button {
    width: 100%;
    max-width: 290px;
    height: 44px;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    color: #9AA6B8;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    border-radius: 3px;
    cursor: pointer;
    margin: 0 auto;
  }
}

select{
  outline: none;
}
.b-select{
  width: 100%;
  height: 72px;
  border-radius: 0px;
  background: #ffffff url('../img/select-arrow.webp');
  background-repeat: no-repeat;
  background-position: right 20px center;
  background-size: 9px;
  -webkit-appearance: none;
  -moz-appearance: none;
  text-indent: 0.01px;
  text-overflow: '';
  -ms-appearance: none;
  appearance: none !important;
}

/* checkbox , radio */
.b-checkbox{
  &:not(:last-child){
    margin: 0 0 10px 0;
  }
  input[type="checkbox"]{
    display: none;
    + label{
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin: 0;
      span{
        display: flex;
        justify-content: center;
        align-items: center;
        width: 20px;
        height: 20px;
        flex-shrink: 0;
        border: 1px solid #D4D9E0;
        border-radius: 3px;
        @include box-sizing;
        margin-right: 10px;
        position: relative;
        cursor: pointer;
        &:before{
          position: absolute;
          top: 50%;
          left: 50%;
          content: "";
          @include translate(-50% ,- 50%);
          background: #000000;
          border-radius: 1px;
          width: 8px;
          height: 8px;
          opacity: 0;
          visibility: hidden;
        }
      }
      i{
        font-weight: 600;
        font-size: 14px;
        line-height: 1.2;
        @include box-sizing;
        color: #000000;
        font-style: normal;
        padding: 1px 0 0 0;
        cursor: pointer;
      }
    }
    &:checked{
      + label{
        span{
          &:before{
            opacity: 1;
            visibility: visible;
          }
        }
      }
    }
  }
}
.b-radio{
  &:not(:last-child){
    margin: 0 0 10px 0;
  }
  input[type="radio"]{
    display: none;
    + label{
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin: 0;
      span{
        display: flex;
        justify-content: center;
        align-items: center;
        width: 20px;
        height: 20px;
        flex-shrink: 0;
        border: 1px solid #D4D9E0;
        border-radius: 100%;
        @include box-sizing;
        margin-right: 10px;
        position: relative;
        cursor: pointer;
        &:before{
          position: absolute;
          top: 50%;
          left: 50%;
          content: "";
          @include translate(-50% ,- 50%);
          background: #000000;
          border-radius: 100%;
          width: 8px;
          height: 8px;
          opacity: 0;
          visibility: hidden;
        }
      }
      i{
        font-weight: 600;
        font-size: 14px;
        line-height: 1.2;
        @include box-sizing;
        color: #000000;
        font-style: normal;
        padding: 1px 0 0 0;
        cursor: pointer;
      }
    }
    &:checked{
      + label{
        span{
          &:before{
            opacity: 1;
            visibility: visible;
          }
        }
      }
    }
  }
}

.fancybox-enabled{
  padding: 0 17px 0 0;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.fancybox-container{
  z-index: 999999;
}
.modal-dialog{
  pointer-events: inherit;
}
.modal{
  z-index: 99999999;
}
.slick-slide , .slick-slide:hover , .slick-slide:focus{
  outline: none !important;
}
.d-none{
  display: none !important;
}

.swiper-pagination{
  position: static !important;
  margin: 55px 0 0 0;
  @include _991{
    margin: 20px 0 0 0;
  }
  .swiper-pagination-bullet{
    width: 45px;
    height: 45px;
    background: transparent;
    border: 3px solid #96DEF7;
    margin: 0 !important;
    opacity: 1 !important;
    @include box-sizing;
    @include transition;
    &:not(:last-child){
      margin-right: 20px !important;
    }
    &.swiper-pagination-bullet-active{
      background: #96DEF7;
    }

  }
}

.b-title{
  text-align: center;
  &__item{
    font-weight: 700;
    font-size: 60px;
    line-height: 75px;
    text-align: center;
    text-transform: uppercase;
    color: #256F2C;
    @include _1680{
      font-size: 40px;
      line-height: 1.2;
    }
    @include _1199{
      font-size: 35px;
    }
    @include _767{
      font-size: 28px;
      line-height: 35px;
      text-transform: uppercase;
    }
  }
  p{
    font-weight: 400;
    font-size: 40px;
    line-height: 60px;
    color: #256F2C;
    @include _1680{
      font-size: 30px;
      line-height: 1.2;
    }
    @include _1199{
      font-size: 25px;
    }
    @include _767{
      font-size: 24px;
    }
  }
}

.b-cookies{
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 999;
  background: rgba(156, 196, 124, 0.8);
  backdrop-filter: blur(3px);
  padding: 30px 0;
  @include box-sizing;
  @include _991{
    padding: 35px 0 35px 0;
  }
  @include _767{
    padding: 56px 0 95px 0;
  }
  .container{
    display: flex;
    justify-content: center;
    align-items: center;
    @include _991{
      display: block;
      text-align: center;
      padding: 0 35px;
    }
  }
  &__text{
    font-weight: 400;
    font-size: 30px;
    line-height: 37px;
    color: #FFFFFF;
    margin-right: 90px;
    @include _1680{
      font-size: 20px;
    }
    @include _991{
      font-size: 18px;
      line-height: 22px;
      max-width: 225px;
      margin: 0 auto 32px;
    }
    a{
      padding-bottom: 2px;
      border-bottom: 1.5px solid #ffffff;
      @include box-sizing;
      @include transition;
      &:hover , &:active , &:focus{
        opacity: 0.7;
        color: #FFFFFF;
        text-decoration: none;
      }
    }
  }
  &__btn{
    width: 150px;
    height: 60px;
    border: 3px solid #FFFFFF;
    border-radius: 20px;
    font-weight: 500;
    font-size: 30px;
    line-height: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
    color: #FFFFFF;
    flex-shrink: 0;
    @include transition;
    @include _991{
      width: 100%;
      height: 60px;
      font-size: 24px;
      line-height: 30px;
      margin: 0 auto 0;
      max-width: 300px;
    }
    &:hover{
      background: #ffffff;
      color: rgba(156, 196, 124, 1);
    }
  }
}


.b-mobile{
  display: none;
  @include _767{
    background: url("../img/burger.svg") center no-repeat;
    width: 45px;
    height: 45px;
    display: block;
    &.active{
      background: url("../img/burger-close.svg") center no-repeat;
      position: relative;
      z-index: 99;
    }
  }
}

.animate-top , .animate-left , .animate-right , .animate-down , .animate-right__fast , .animate-left__fast , .animate-back-in-right{
  opacity: 0;
  transition: 1s;
  @include _767{
    opacity: 1;
  }
}

section{
  padding: 120px 0 !important;
  @include _1680{
    padding: 60px 0 !important;
  }
  @include _767{

  }
}