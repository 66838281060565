.b-info{
  background: url("../img/main.webp") center no-repaet;
  background-size: cover;
  padding: 290px 0 90px 0 !important;
  @include box-sizing;
  position: relative;
  @include _1680{
	padding: 90px 0 !important;
  }
  @include _767{
	padding: 64px 0 45px 0 !important;
  }
  &:before{
	position: absolute;
	top: -309px;
	left: 0;
	content: "";
	background: url("../img/desktop.png") center no-repeat;
	width: 100%;
	height: 100%;
	z-index: 1;
	@include _1680{
	  top: -87px;
	  background-size: cover;
	}
	@include _767{
	  background: url("../img/mobile.png") center no-repeat;
	  top: -342px;
	  left: -15px;
	}
  }
  .container{
	position: relative;
	z-index: 2;
  }
  &__text{
	text-align: center;
	font-weight: 700;
	font-size: 58px;
	line-height: 73px;
	color: #28722F;
	margin: 0 0 160px 0;
	@include _1680{
	  font-size: 35px;
	  line-height: 1.25;
	}
	@include _767{
	  font-size: 28px;
	  line-height: 35px;
	  margin: 0 0 85px 0;
	}
  }
  &__block{
	display: flex;
	justify-content: center;
	align-items: center;
	margin: 0 auto 45px;
	@include _767{
	  display: block;
	  text-align: center;
	}
	&-left{
	  font-weight: 700;
	  font-size: 240px;
	  line-height: 1;
	  text-align: right;
	  color: #28722F;
	  margin: 0 45px 0 0;
	  @include _1680{
		font-size: 120px;
	  }
	  @include _767{
		font-size: 140px;
		text-align: center;
		margin: 0;
	  }
	}
	&-right{
	  padding: 16px 0 0 0;
	  width: 672px;
	  @include box-sizing;
	  @include _767{
		width: 100%;
	  }
	  p{
		font-weight: 400;
		font-size: 32px;
		line-height: 40px;
		color: #000000;
		@include _1680{
		  font-size: 22px;
		}
		@include _767{
		  font-size: 20px;
		  line-height: 1.15;
		}
	  }
	  b{
		font-weight: 500;
		font-size: 36px;
		line-height: 45px;
		color: #000000;
		@include _1680{
		  font-size: 22px;
		  line-height: 1.25;
		}
	  }
	}
  }
  @include _767{
	&__bottom{
	  overflow-x: scroll;
	  overflow-y: auto;
	  -webkit-overflow-scrolling: touch;
	  img{
		width: 1200px;
		max-width: inherit;
	  }
	}
  }
}