.b-video{
  position: relative;
  max-width: 1920px;
  margin: 0 auto 0;
  #plvideo2{
	display: none;
  }
  &__play{
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 93%;
	background: url("../img/play.svg") center no-repeat;
	background-size: 269px;
	@include _1680{
	  background-size: 200px;
	}
	@include _1199{
	  background-size: 150px;
	}
	@include _991{
	  background-size: 128px;
	}
	@include _767{
	  display: none;
	}
	&.active{
	  opacity: 0;
	  @include transition;
	}
  }
  &__xs{
	display: none;
	height: 100%;
  }
  @include _767{
	height: 806px;
	&__desc{
	  display: none;
	}
	&__xs{
	  display: block;
	  height: 100%;
	}
	#plvideo1{
	  display: none;
	}
	#plvideo2{
	  display: block;
	}
  }
  video{
	width: 100%;
	height: 100%;
	object-fit: cover;
  }
  video[poster]{
	height:100%;
	width:100%;
	object-fit: cover;
  }
  &:before{
	position: absolute;
	bottom: -522px;
	left: 0;
	content: "";
	background: url("../img/vl.png") center no-repeat;
	width: 535px;
	height: 895px;
	@include _1680{
	  bottom: -287px;
	  height: 503px;
	  max-width: 299px;
	  background-size: contain;
	}
	@include _1199{
	  display: none;
	}
  }
  &:after{
	position: absolute;
	bottom: -282px;
	right: 0;
	content: "";
	background: url("../img/vr.png") center no-repeat;
	width: 404px;
	height: 282px;
	@include _1680 {
	  bottom: -207px;
	  height: 208px;
	  max-width: 299px;
	  background-size: contain;
	}
	@include _1199{
	  display: none;
	}
  }
}