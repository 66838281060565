@mixin box-sizing{
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
@mixin transform($transforms) {
  -webkit-transform: $transforms;
  -moz-transform: $transforms;
  -ms-transform: $transforms;
  transform: $transforms;
}
@mixin translate ($x, $y) {
  @include transform(translate($x, $y));
}
@mixin transition{
  -webkit-transition: all .3s ease-in-out;
  -moz-transition:    all .3s ease-in-out;
  -o-transition:      all .3s ease-in-out;
  -ms-transition:     all .3s ease-in-out;
  transition:         all .3s ease-in-out;
}
@mixin _1810 {
  @media (max-width: 1810px){
    @content;
  }
}
@mixin _1680 {
  @media (max-width: 1679px){
    @content;
  }
}
@mixin _1199 {
  @media (max-width: 1199px){
	@content;
  }
}
@mixin _991 {
  @media (max-width: 991px){
	@content;
  }
}
@mixin _767 {
  @media (max-width: 767px){
	@content;
  }
}
@mixin _530 {
  @media (max-width: 530px){
    @content;
  }
}